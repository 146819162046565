import $ from 'jquery';

import { hideElement, showElement } from './transition-aware-show-hide.js';

const NAME = 'toggleVisibility';
const DATA_KEY = `jax.${NAME}`;

/**
 * A custom jQuery wrapper for our transition-aware show & hide element fuctions.
 */

class ToggleVisibility {
  constructor(element) {
    this._element = $(element);
  }

  show() {
    showElement(this._element);
  }

  hide() {
    hideElement(this._element);
  }

  /**
   * Interface for using this class as a jQuery plugin.
   *
   * @param {string} [method] - Name of a method to call.
   * @returns {jQuery}
   */

  static _jQueryInterface(config, ...args) {
    return this.each(function () {
      const $this = $(this);
      let data = $this.data(DATA_KEY);

      if (!data) {
        data = new ToggleVisibility(this);
        $this.data(DATA_KEY, data);
      }

      if (typeof config === 'string') {
        if (data[config] === undefined) {
          throw new Error(`No method named "${config}"`);
        }

        data[config](...args);
      }
    });
  }
}

$.fn[NAME] = ToggleVisibility._jQueryInterface;
$.fn[NAME].Constructor = ToggleVisibility;

export default ToggleVisibility;
